// 抽离成公共方法
// export const awaitWrap = async (promise: Promise<any>) => {
//   try {
//     const data = await promise;
//     return { code: 200, data };
//   } catch (err) {
//     return { code: 400, err };
//   }
// };

export async function awaitWrap(promise: Promise<any>): Promise<any> {
  try {
    const data = await promise;
    return { code: 200, data };
  } catch (err) {
    return { code: 400, err };
  }
}

//生成随机ID
export function GenNonDuplicateID(randomLength: number): string {
  return Number(Math.random().toString().substr(3, randomLength) + Date.now())
    .toString(36)
    .toUpperCase();
}

//获取司机年月日
export function dateFormat(fmt: string): string {
  const date = new Date();
  const o: any = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'H+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return fmt;
}
